import { Outlet, useNavigate } from "react-router-dom";
import { CategoriesComponent } from "../components/sidePanel/categories/CategoriesComponent";
import { SidePanel } from "../components/sidePanel/SidePanel";
import { TopPanel } from "../components/TopPanel";
import { Category } from "../components/sidePanel/categories/Category";
import { changeCurrentProduct } from "../../common/commonSlice";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  billClient,
  changeActiveComponent,
  changeCurrentFilters,
  getBillingOptions,
  getReportOptions,
  selectBillInfo,
  selectBillingOptions,
  selectCurrentFiltered,
  selectCurrentPeriod,
  selectDataClients,
  selectLoadingBillInfo,
  selectServiceList,
  selectVisualization,
  setConfigClientId,
  setCurrentClientId,
  setCurrentReport,
  setNameClient,
  setSelectService,
  setShowReportFieldsModal,
  setVisualization,
} from "./BillingSlice";
import { selectBillingComponent } from "./BillingSlice";
import { useEffect, useState } from "react";
import { FilterComponent } from "../components/sidePanel/FilterComponent";
import { DropDown } from "../../common/components/Btns/DropDown";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { serviceIdToName } from "./helpers/utils";
import { ClipLoader } from "react-spinners";

export const Billing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const activeComponent = useAppSelector(selectBillingComponent);
  const currentFilters = useAppSelector(selectCurrentFiltered);
  const listService = useAppSelector(selectServiceList);
  const selectedVisualization = useAppSelector(selectVisualization);
  const dataClients = useAppSelector(selectDataClients);
  const billingOptions = useAppSelector(selectBillingOptions);
  const billingInfo = useAppSelector(selectBillInfo);
  const selectedPeriodInfo = useAppSelector(selectCurrentPeriod);
  const loadingBillInfo = useAppSelector(selectLoadingBillInfo);

  useEffect(() => {
    if (activeComponent === "none") {
      dispatch(changeActiveComponent("General"));
    }
  }, [activeComponent]);

  const handleVisualizationChange = (event: any) =>
    dispatch(setVisualization(event));

  const onChangeFilterClient = (id: string | null, label?: string | null) => {
    dispatch(changeCurrentFilters({ ...currentFilters, clientId: id }));

    if (label) {
      dispatch(setNameClient(label));
    }
  };

  const billingOptionsFilter = (): { label: string; value: string }[] => {
    const result: { label: string; value: string }[] = [];
    if (billingOptions) {
      if (billingOptions.bills && billingOptions.bills.length > 0) {
        billingOptions.bills.forEach((bill) => {
          const endDate = new Date(bill.endDateBilling!);
          const formatDate = format(endDate, "MMMM yyyy", { locale: es });
          if (bill.invoiceIssued) {
            result.push({
              label: formatDate,
              value: bill.id!,
            });
          } else {
            result.push({
              label: `Pendiente: ${formatDate}`,
              value: bill.endDateBilling!,
            });
          }
        });
      }
      return result;
    } else {
      return [];
    }
  };

  const serviceToBillFilter = (): { label: string; value: string }[] => {
    const result: { label: string; value: string }[] = [];
    if (billingInfo?.reports) {
      Object.entries(billingInfo.reports).forEach(([key]) => {
        result.push({
          label: serviceIdToName(key),
          value: key,
        });
      });
      return result;
    } else {
      return [];
    }
  };

  const buttonGenerarReporte = () => {
    const bill = billingOptions?.bills?.find(
      (bill) => bill.id === selectedPeriodInfo?.billingId
    );
    return (
      <button
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          padding: "5px 20px",
          borderRadius: "8px",
          border: "none",
          fontSize: "14px",
          color: "white",
          width: "95%",
          fontWeight: "600",
          marginBottom: "30px",
        }}
        onClick={() => {
          dispatch(
            billClient({
              billedId: bill ? bill.id : undefined,
              generateInvoice: false,
              generateReport: true,
            })
          );
        }}
      >
        {loadingBillInfo === "pending" ? (
          <ClipLoader color="#fff" size={"20px"} />
        ) : (
          "Descargar Reporte"
        )}
      </button>
    );
  };

  const buttonFacturar = () => {
    const bill = billingOptions?.bills?.find(
      (bill) => bill.id === selectedPeriodInfo?.billingId
    );
    if (!bill || !bill.invoiceIssued) {
      return (
        <button
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            padding: "10px 20px",
            borderRadius: "8px",
            border: "none",
            fontSize: "14px",
            color: "white",
            width: "95%",
            fontWeight: "600",
            marginBottom: "20px",
          }}
          onClick={() => {
            dispatch(billClient({ generateInvoice: true }));
          }}
        >
          {loadingBillInfo === "pending" ? (
            <ClipLoader color="#fff" size={"20px"} />
          ) : (
            "Generar Prefactura"
          )}
        </button>
      );
    } else {
      if (bill && bill.invoiceIssued) {
        return (
          <button
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              padding: "10px 20px",
              borderRadius: "8px",
              border: "none",
              fontSize: "14px",
              color: "white",
              width: "95%",
              fontWeight: "600",
              marginBottom: "20px",
            }}
            onClick={() => {
              dispatch(billClient({ billedId: bill.id! }));
            }}
          >
            {loadingBillInfo === "pending" ? (
              <ClipLoader color="#fff" />
            ) : (
              "Ver Factura"
            )}
          </button>
        );
      } else {
        return <></>;
      }
    }
  };

  useEffect(() => {
    if (dataClients && selectedVisualization === "Clienteyservicio") {
      const clientOptions =
        dataClients?.map((client) => ({
          label: client.name,
          value: client.id,
        })) || [];

      onChangeFilterClient(clientOptions[0].value, clientOptions[0].label);
    } else {
      onChangeFilterClient(null, null);
    }
  }, [dataClients, selectedVisualization]);

  const filterOptionGeneral = () => {
    return (
      <>
        <p
          style={{
            color: "white",
            width: "185px",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          Rango de fechas
        </p>
        <div style={{ display: "flex" }}>
          <button
            className={
              currentFilters.simpleDateFilter === "MONTH"
                ? "btn-filter"
                : "btn-filter-unselected"
            }
            onClick={() => {
              dispatch(
                changeCurrentFilters({
                  ...currentFilters,
                  simpleDateFilter: "MONTH",
                })
              );
            }}
          >
            Mensual
          </button>
          <button
            className={
              currentFilters.simpleDateFilter === "WEEK"
                ? "btn-filter-2 btn-filter"
                : "btn-filter-unselected btn-filter-2"
            }
            onClick={() => {
              dispatch(
                changeCurrentFilters({
                  ...currentFilters,
                  simpleDateFilter: "WEEK",
                })
              );
            }}
          >
            Semanal
          </button>
        </div>
      </>
    );
  };

  const FilterOptionsAnalytics = () => {
    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          color: "#ffffff",
          marginTop: "10px",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <DropDown
            key={"Visualizacion"}
            title="Visualización"
            defaultValue={{ label: "Clientes", value: "Clientes" }}
            options={[
              { label: "Clientes", value: "Clientes" },
              { label: "Servicios", value: "Servicios" },
              { label: "Cliente y servicios", value: "Clienteyservicio" },
            ]}
            onChange={(e) => {
              if (e) {
                handleVisualizationChange(e?.value);
              }
            }}
          />
        </div>
        {selectedVisualization === "Clientes" && (
          <div style={{ marginBottom: "20px" }}>
            <DropDown
              key={"Clientes"}
              title="Por cliente"
              options={
                dataClients?.map((client) => ({
                  label: client.name,
                  value: client.id,
                })) || []
              }
              onChange={(e) => {
                if (e) {
                  onChangeFilterClient(e?.value);
                } else {
                  onChangeFilterClient(null);
                }
              }}
            />
          </div>
        )}
        {selectedVisualization === "Clienteyservicio" && (
          <div style={{ marginBottom: "20px" }}>
            <DropDown
              key={"Cliente y servicio"}
              title="Por cliente"
              options={
                dataClients?.map((client) => ({
                  label: client.name,
                  value: client.id,
                })) || []
              }
              useFirstOptionAsDefault={
                selectedVisualization === "Clienteyservicio"
              }
              onChange={(e) => {
                if (e) {
                  onChangeFilterClient(e?.value, e?.label);
                }
              }}
            />
          </div>
        )}

        {selectedVisualization === "Servicios" && (
          <div style={{ marginBottom: "20px" }}>
            <DropDown
              key="Servicios"
              title="Por servicio"
              options={
                Object.keys(listService || {}).map((service) => ({
                  label: service,
                  value: service,
                })) || []
              }
              onChange={(e) => dispatch(setSelectService(e?.value ?? ""))}
            />
          </div>
        )}
      </div>
    );
  };

  const filterOptionReports = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          borderRadius: "8px",
          color: "#ffffff",
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <DropDown
            key="Clientes2"
            title="Cliente"
            options={
              dataClients?.map((client) => ({
                label: client.name,
                value: client.id,
              })) || []
            }
            onChange={(e) => {
              if (e) {
                dispatch(setCurrentClientId(e.value));
                dispatch(getBillingOptions(e.value));
                dispatch(getReportOptions(e.value));
              }
            }}
          />
        </div>
        {billingOptionsFilter().length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <DropDown
              key="Periodo"
              title="Periodo de Facturación"
              options={billingOptionsFilter()}
              onChange={(e) => {
                if (e) {
                  if (e.label.includes("Pendiente")) {
                    dispatch(billClient({ dateToGenerate: e.value }));
                  } else {
                    dispatch(billClient({ billedId: e.value }));
                  }
                }
              }}
            />
          </div>
        )}
        {serviceToBillFilter().length > 0 && (
          <div style={{ marginBottom: "20px" }}>
            <DropDown
              key="BillingService"
              title="Visualizar Servicio"
              options={serviceToBillFilter()}
              onChange={(e) => {
                if (e) {
                  const report = billingInfo?.reports?.[e.value];
                  dispatch(setCurrentReport({ report, name: e.value }));
                }
              }}
            />
          </div>
        )}{" "}
        {serviceToBillFilter().length > 0 && (
          <div
            style={{
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <button
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                padding: "10px 20px",
                borderRadius: "8px",
                border: "none",
                fontSize: "14px",
                color: "white",
                fontWeight: "600",
              }}
              onClick={() => {
                dispatch(setShowReportFieldsModal(true));
              }}
            >
              Modificar Campos
            </button>
          </div>
        )}
      </div>
    );
  };

  const FilterOptionsConfig = () => {
    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          borderRadius: "8px",
          color: "#ffffff",
          marginTop: "10px",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <DropDown
            key="Clientes"
            title="Cliente"
            options={
              dataClients?.map((client) => ({
                label: client.name,
                value: client.id,
              })) || []
            }
            onChange={(e) => {
              if (e) {
                dispatch(setConfigClientId(e.value));
                dispatch(setNameClient(e.label));
              } else {
                dispatch(setConfigClientId(null));
              }
            }}
          />
        </div>
      </div>
    );
  };

  const getFilterItemsByActiveComponent = (): JSX.Element | undefined => {
    if (activeComponent === "General") {
      return (
        <>
          <FilterComponent
            key={0}
            hasCalendar={false}
            items={[filterOptionGeneral()]}
          />
          ;
        </>
      );
    }
    if (activeComponent === "Analytics") {
      return (
        <>
          <FilterComponent
            key={1}
            calendarType="double"
            hasCalendar={false}
            open={true}
            items={[FilterOptionsAnalytics()]}
            endDate={
              currentFilters.endDate ? new Date(currentFilters.endDate) : null
            }
            startDate={
              currentFilters.startDate
                ? new Date(currentFilters.startDate)
                : null
            }
            setEndDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilters,
                    endDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilters,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
            setStartDate={(date: Date) => {
              if (date) {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilters,
                    startDate: date.toISOString(),
                  })
                );
              } else {
                dispatch(
                  changeCurrentFilters({
                    ...currentFilters,
                    endDate: null,
                    startDate: null,
                  })
                );
              }
            }}
          />
          ;
        </>
      );
    }
    if (activeComponent === "Config") {
      return (
        <>
          <FilterComponent
            key={2}
            open={true}
            hasCalendar={false}
            items={[FilterOptionsConfig()]}
          />
        </>
      );
    }
    if (activeComponent === "Reports") {
      return (
        <>
          <FilterComponent
            key={3}
            open={true}
            hasCalendar={false}
            items={[filterOptionReports()]}
          />
          {serviceToBillFilter().length > 0 && (
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {buttonGenerarReporte()}
              {buttonFacturar()}
            </div>
          )}
        </>
      );
    }
  };
  const getCategoryItems = () => {
    const categories: JSX.Element[] = [];
    categories.push(
      <Category
        key={0}
        name="General"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Facturación"));
          dispatch(changeActiveComponent("General"));
          navigate("/billing/general");
        }}
        selected={activeComponent === "General"}
      />
    );
    categories.push(
      <Category
        key={1}
        name="Analítica"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Facturación"));
          dispatch(changeActiveComponent("Analytics"));
          navigate("/billing/analytics");
        }}
        selected={activeComponent === "Analytics"}
      />
    );
    categories.push(
      <Category
        key={2}
        name="Reportes de facturación"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Facturación"));
          dispatch(changeActiveComponent("Reports"));
          navigate("/billing/reports");
        }}
        selected={activeComponent === "Reports"}
      />
    );
    categories.push(
      <Category
        key={3}
        name="Configuración"
        onClick={(e) => {
          e.preventDefault();
          dispatch(changeCurrentProduct("Facturación"));
          dispatch(changeActiveComponent("Config"));
          navigate("/billing/config");
        }}
        selected={activeComponent === "Config"}
      />
    );

    return categories;
  };

  return (
    <div
      className="flex-col"
      style={{
        paddingLeft: "0.5%",
        paddingTop: "0.5%",
        height: "100%",
        width: "100%",
        overflow: "auto",
      }}
    >
      <TopPanel />

      <div
        className="flex-row"
        style={{ justifyContent: "start", height: "95%" }}
      >
        <SidePanel
          filter={getFilterItemsByActiveComponent()}
          categories={<CategoriesComponent categories={getCategoryItems()} />}
        />
        <div
          className="flex-col"
          style={{ width: "80%", justifyContent: "end" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};
